<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>List of  Personnel Move</h1>
          </div>
          <div class="row">
            <div class="col-md-6">
              <v-text-field v-model="searchPersonnel"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
            </div>
            <!-- <div v-if="userRole !== appRoles.LCE" class="col-md-6 mt-2">

            <SelectField
              :items="contractEmployers"
              v-model="contractEmployerId"
              :label="'Contractor Employer'"
              :itemText="'name'"
              :itemValue="'id'"
              @onChange="contractEmployerChange"
            />
          </div> -->
          </div>
          <v-data-table loading-text="Loading personnel Move. Please wait...."
            :loading="isLoading"
            :headers='headers'
            :items="personnelMoveList"
            :search="searchPersonnel"
            item-key="id">
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <Button :btnType="'Submit'" :label="'Veiw'" @onClick="edit(item)" class="mr-2" />
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span v-if="item.status == 0">Pending</span>
              <span v-if="item.status == 1">Approved</span>
            </template>
            <template v-slot:[`item.lastModifiedDate`]="{ item }">
              {{item.lastModifiedDate | formatDate}}
            </template>

          </v-data-table>

          <!-- View modal  -->
          <Dialog ref="personnelMoveViewModal" :title="'Personnel Move Detail'" :width="700">
            <div v-if="isLoading" class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <v-row v-if="oldPresonnel.length !==0 & newPersonnelMove.length !==0">
              <div class="row text-left">
                <div class="col-md-12"><b>Current Personnel Details </b></div>
                <div class="text-caption col-md-6">Job Title: </div>
                <div class="col-md-6"><b>{{oldPresonnel.jobTitle.name}}</b></div>
                <div class="text-caption col-md-6">Department: </div>
                <div class="col-md-6"><b> {{oldPresonnel.department.name}}</b></div>
                <div class="text-caption col-md-6">Work Area: </div>
                <div class="col-md-6"><b> {{oldPresonnel.workArea.name}}</b></div>
                <div class="text-caption col-md-6">Cost Centre: </div>
                <div class="col-md-6"><b> {{oldPresonnel.chargeCode}}</b></div>
                <div class="text-caption col-md-6">Location: </div>
                <div class="col-md-6"><b> {{oldPresonnel.location.name}}</b></div>
                <div class="text-caption col-md-6">Rotation: </div>
                <div class="col-md-6"><b> {{oldPresonnel.rotation.name}}</b></div>

                <div class="col-md-12"><b>Proposed Personnel Move Details </b></div>
                <div class="text-caption col-md-6">Job Title: </div>
                <div class="col-md-6"><b>{{newPersonnelMove.jobTitle.name}}</b></div>
                <div class="text-caption col-md-6">Department: </div>
                <div class="col-md-6"><b> {{newPersonnelMove.department.name}}</b></div>
                <div class="text-caption col-md-6">Work Area: </div>
                <div class="col-md-6"><b> {{newPersonnelMove.workArea.name}}</b></div>
                <div class="text-caption col-md-6">Cost Centre: </div>
                <div class="col-md-6"><b> {{newPersonnelMove.chargeCode}}</b></div>
                <div class="text-caption col-md-6">Location: </div>
                <div class="col-md-6"><b> {{newPersonnelMove.location.name}}</b></div>
                <div class="text-caption col-md-6">Rotation: </div>
                <div class="col-md-6"><b> {{newPersonnelMove.rotation.name}}</b></div>
              </div>
            </v-row>
            <v-divider></v-divider>
            <template v-slot:footer>
            </template>
          </Dialog>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>

import Button from '@/components/ui/form/Button.vue'

import Dialog from '@/components/ui/Dialog.vue'
import { userService, employeeMoveAuditTrailService, employeeService } from '@/services'
import Roles from '@/utils/roles'

export default {
  components: {
    Button,
    Dialog
  },
  data () {
    return {
      search: '',
      userRole: '',
      appRoles: {},
      isLoading: false,
      formData: {
        name: '',
        id: ''
      },
      contractEmployerId: 0,
      personnelMoveList: [],
      contractEmployers: [],
      oldPresonnel:[],
      newPersonnelMove:[],
      personnel: {},
      searchPersonnel: '',
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      allLoading: 0,
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Cai',
          value: 'employee.cai',
          fixed: true,
          width: '100px',
          sortable: false
        },
        {
          text: 'Prnumber',
          value: 'employee.prnumber',
          fixed: true,
          width: '100px'
        },
        {
          text: 'Charge Code',
          value: 'chargeCode'
        },
        {
          text: 'Job Title',
          value: 'jobTitle.name'
        },
        {
          text: 'First Name',
          value: 'employee.firstName'
        },
        {
          text: 'Last Name',
          value: 'employee.lastName'
        },
        {
          text: 'Email',
          value: 'employee.email'
        },
        {
          text: 'Location',
          value: 'location.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy',
          sortable: false
        },
        {
          text: 'Date Modified',
          value: 'lastModifiedDate'
        },
        {
          text: 'Actions',
          value: 'actions',
          fixed: true,
          sortable: false
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },

    edit (item) {
      this.isLoading = true
      employeeService.getEmployeesById(item.employeeId).then(({ data }) => {
        this.oldPresonnel = data
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false
      })
      employeeMoveAuditTrailService.getPersonnelById(item.id).then(({ data }) => {
        this.newPersonnelMove = data
      }).catch(() => {
      }).finally(() => {
        this.isLoading = false
      })
      this.$refs.personnelMoveViewModal.openModal()

    },
    openDeleteModal (item) {
      this.personnel = item
      this.$refs.deleteModal.toggleModal()
      this.formData.name = `${item.firstName}, ${item.lastName} ${item.middleName}`
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    contractEmployerChange (item) {
      this.employeeList = []
      this.isLoading = true
      if (item === 0.1) {
        this.getAllEmployee()
      }
    },
    getAllMoveList () {
      employeeMoveAuditTrailService.getAllPersonnelMove().then(result => {
        this.personnelMoveList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    this.isLoading = true
    this.appRoles = Roles

    userService.getCurrentUser().then(result => {
      this.userRole = result.data.roleName
    })
    this.getAllMoveList()
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
